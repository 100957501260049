import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom';

import Zoom from 'react-reveal/Zoom';

import sw1 from '../../img/Home/sw5.png'
import sw2 from '../../img/Home/sw1.png'
import sw3 from '../../img/Home/sw2.png'
import sw4 from '../../img/Home/sw3.png'
import sw5 from '../../img/Home/sw4.png'

import black from '../../img/Home/black.png'

const data = [
    {
        title: 'main.6',
        text: 'main.11',
        img: sw1
    },
    {
        title: 'main.7',
        text: 'main.12',
        img: sw2
    },
    {
        title: 'main.8',
        text: 'main.13',
        img: sw3
    },
    {
        title: 'main.9',
        text: 'main.14',
        img: sw4
    },
]

function AccountTypes() {

  const { t } = useTranslation();

  const [active, setActive] = useState(0);

  return (
    <div className='bg-[#000] xl:h-[550px] mt-[80px] xl:mt-[160px]'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto'>
            <div className='flex flex-col xl:flex-row justify-between relative'>
                <div className='relative pt-[60px]'>
                    <p className='uppercase text-[#98CBFF] xl:text-[45px] text-[20px] oswald font-[700] text-center xl:text-start'>{t('main.5')}</p>
                    <div className='flex flex-wrap gap-[70px] xl:gap-[50px] pt-[50px] justify-center xl:justify-start'>
                        {data.map((text, index) => ( 
                            <div className='cursor-pointer flex flex-col items-center' key={index} onClick={() => setActive(index)}>
                                <p className='text-[#98CBFF] oswald font-[700] text-[14px] xl:text-[17px] pb-[15px] uppercase'>{t(text.title)}</p>
                                {active === index ? 
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                            <circle cx="15" cy="15.0003" r="10.8333" fill="url(#paint0_linear_282_176)"/>
                                            <circle cx="15" cy="15" r="14.5" stroke="url(#paint1_linear_282_176)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_282_176" x1="15" y1="4.16699" x2="15" y2="25.8337" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00B2FF"/>
                                                <stop offset="0.146667" stop-color="#0AA9FA"/>
                                                <stop offset="1" stop-color="#4670DA"/>
                                                </linearGradient>
                                                <linearGradient id="paint1_linear_282_176" x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00B2FF"/>
                                                <stop offset="0.146667" stop-color="#0AA9FA"/>
                                                <stop offset="1" stop-color="#4670DA"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div> 
                                : 
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                            <circle cx="15" cy="15" r="14.5" stroke="url(#paint0_linear_282_179)"/>
                                            <defs>
                                                <linearGradient id="paint0_linear_282_179" x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#00B2FF"/>
                                                <stop offset="0.146667" stop-color="#0AA9FA"/>
                                                <stop offset="1" stop-color="#4670DA"/>
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </div>
                                }
                            </div> 
                        ))}
                    </div>
                    <p className='text-white max-w-[525px] open-sans text-[17px] font-[300] pt-[28px] text-center xl:text-start h-[150px] xl:h-auto mx-auto xl:mx-0'>{t(data[active].text)}</p>    
                   <Link to="https://user.fips-finance.net/">
                        <div className='button-main mt-[35px] cursor-pointer mx-auto xl:mx-0'>
                            <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.16')}</p>
                        </div>
                    </Link>              
                </div>
                <div className='mt-[50px] xl:mt-0 h-[400px] xl:h-0'>
                    <Zoom key={active} bottom right>
                        <img src={data[active].img} alt="" className={`mx-auto xl:mx-0 mt-[20px] ${active === 4 ? '' : 'xl:mr-[50px]'}`}/>
                    </Zoom>
                    <img src={black} alt="" className='hidden xl:block absolute bottom-0 xl:top-0 right-0'/>
                </div>
            </div>
        </div>
    </div>

  );
}

export default AccountTypes;