import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

const data = [
    {
        title:  'accounts.5',
        answer: 'accounts.6',
    },
    {
        title:  'accounts.7',
        answer: 'accounts.8',
    },
    {
        title:  'accounts.9',
        answer: 'accounts.10',
    },
    {
        title:  'accounts.11',
        answer: 'accounts.12',
    },
    {
        title:  'accounts.13',
        answer: 'accounts.14',
    },
    {
        title:  'accounts.15',
        answer: 'accounts.16',
    },
    {
        title:  'accounts.17',
        answer: 'accounts.18',
    },
    {
        title:  'accounts.19',
        answer: 'accounts.20',
    },
]

function HowСhoose() {

  const { t } = useTranslation();

  const [openIndex, setOpenIndex] = useState(1);

  const toggleText = (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='relative overflow-hidden'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col items-center'>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF] text-center'>{t('accounts.3')}</p>
            <p className='max-w-[730px] open-sans font-[300] xl:text-[17px] text-[#FFF] mt-[20px] text-center'>{t('accounts.4')}</p>
            <div className="flex justify-between flex-col mx-[20px] xl:mx-0 mt-[20px] xl:mt-[100px] xl:w-[1280px]">
                {data.map((faq, index) => (
                    <div key={index} onClick={() => toggleText(index)} className='cursor-pointer'>
                        <div className={`flex justify-between mt-[5px] transition-all duration-300`}>
                            <div className='xl:mx-[25px]'>
                                <p className='mt-[15px] oswald text-[14px] xl:text-[20px] text-[#98CBFF] font-[600] tracking-[3.4px]'>0{index + 1}. {t(faq.title)}</p>
                                {openIndex === index && (
                                <p className='text-[#E7E7E7] xl:ml-[42px] open-sans text-[14px] xl:text-[17px] font-light mt-[10px] max-w-[757px] tracking-[2.89px]'>{t(faq.answer)}</p>
                                )}
                            </div>
                        </div>
                        <div className='h-[1px] bg-white mt-[20px]'></div>
                    </div>
                ))}
            </div>
        </div>
    </div>

  );
}

export default HowСhoose;