import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import Fade from 'react-reveal/Fade'

import te1 from '../../img/Home/te1.png'
import te2 from '../../img/Home/te2.png'
import bl from '../../img/Home/blick2.png'

import rocket from '../../img/Home/rocket.gif'

function TryExceptional() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#000] mt-[80px] xl:mt-[160px] relative overflow-hidden pb-[100px] xl:pb-[200px]'>
        <p className='text-center mx-auto oswald text-[20px] xl:text-[50px] text-[#98CBFF] uppercase font-[700]'>{t('main.38')}</p>
        <Fade left><img src={te1} alt="" className='absolute left-0 top-0'/></Fade>
        <Fade right><img src={te2} alt="" className='absolute right-0 hidden xl:block'/></Fade>
        <Fade left bottom duration={4500}><img src={bl} alt="" className='absolute bottom-0 left-0'/></Fade>
        <Fade bottom left duration={3000}><img src={rocket} className='absolute left-[200px] top-[300px] w-[150px]' alt="" /></Fade>
        <div className='flex flex-col max-w-[1280px] mx-[20px] xl:mx-auto items-center mt-[40px] xl:mt-[70px] relative z-50'>
            <div className='flex gap-[25px]'>
                <p className='text-[#98CBFF] oswald text-[40px] xl:text-[70px] font-[700] uppercase'>1</p>
                <p className='text-[14px] xl:text-[17px] text-white open-sans font-[300] w-[230px] mt-[10px] xl:mt-[40px]'><Trans>{t('main.39')}</Trans></p>
            </div>
            <div className='h-[120px] w-px bg-white mr-[250px]'></div>
            <div className='flex gap-[25px]'>
                <p className='text-[#98CBFF] oswald text-[40px] xl:text-[70px] font-[700] uppercase'>2</p>
                <p className='text-[14px] xl:text-[17px] text-white open-sans font-[300] w-[230px] mt-[10px] xl:mt-[40px]'><Trans>{t('main.40')}</Trans></p>
            </div>
            <div className='h-[120px] w-px bg-white mr-[250px]'></div>
            <div className='flex gap-[25px]'>
                <p className='text-[#98CBFF] oswald text-[40px] xl:text-[70px] font-[700] uppercase'>3</p>
                <p className='text-[14px] xl:text-[17px] text-white open-sans font-[300] w-[230px] mt-[10px] xl:mt-[40px]'><Trans>{t('main.41')}</Trans></p>
            </div>
        </div>
    </div>

  );
}

export default TryExceptional;