import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import arrow from '../../img/SupportFaq/arrow.png'

import s1 from '../../img/SupportFaq/1.png'
import s2 from '../../img/SupportFaq/2.png'
import s3 from '../../img/SupportFaq/3.png'

function CustomerSupport() {

  const { t } = useTranslation();
  
  const [active, setActive] = useState(0);

  const next = () => {
    if (active < 2) {
        setActive(active + 1);
    }
    else{
        setActive(0);
    }
  };

  const prev = () => {
    if (active > 0) {
        setActive(active - 1);
    } else {
        setActive(2);
    }
};

  return (
    <div className='relative overflow-hidden'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col'>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF]'>{t('supportfaq.9')}</p>
            <p className='max-w-[554px] open-sans font-[300] xl:text-[17px] text-[#FFF] mt-[20px]'>{t('supportfaq.10')}</p>
            <div className='xl:flex mt-[75px] justify-between items-center hidden'>
                <img src={arrow} alt="" className='rotate-180 mr-[-40px] z-50 cursor-pointer' onClick={() => prev()}/>
                <div className={`transition-all relative rounded-[20px] overflow-hidden ${active === 0 ? 'w-[600px] h-[395px]' : 'w-[300px] h-[395px]'}`}>
                    <img src={s1} alt="" className='object-cover h-full w-full'/>
                    <div class="absolute inset-0 bg-black opacity-50"></div>
                    <div className='absolute inset-[50px]'>
                        <p className='text-[14px] xl:text-[18px] text-[#98CBFF] oswald font-[600]'>{t('supportfaq.11')}</p>
                        <p className={`text-white max-w-[332px] font-[300] open-sans text-[14px] xl:text-[16px] mt-[15px] ${active === 0 ? 'block' : 'hidden'}`}>{t('supportfaq.12')}</p>
                    </div>
                </div>
                <div className={`transition-all relative rounded-[20px] overflow-hidden ${active === 1 ? 'w-[600px] h-[395px]' : 'w-[300px] h-[395px]'}`}>
                    <img src={s2} alt="" className='object-cover h-full w-full'/>
                    <div class="absolute inset-0 bg-black opacity-50"></div>
                    <div className='absolute inset-[50px]'>
                        <p className='text-[14px] xl:text-[18px] text-[#98CBFF] oswald font-[600]'>{t('supportfaq.13')}</p>
                        <p className={`text-white max-w-[332px] font-[300] open-sans text-[14px] xl:text-[16px] mt-[15px] ${active === 1 ? 'block' : 'hidden'}`}>{t('supportfaq.14')}</p>
                    </div>
                </div>
                <div className={`transition-all relative rounded-[20px] overflow-hidden ${active === 2 ? 'w-[600px] h-[395px]' : 'w-[300px] h-[395px]'}`}>
                    <img src={s3} alt="" className='object-cover h-full w-full'/>
                    <div class="absolute inset-0 bg-black opacity-50"></div>
                    <div className='absolute inset-[50px]'>
                        <p className='text-[14px] xl:text-[18px] text-[#98CBFF] oswald font-[600]'>{t('supportfaq.15')}</p>
                        <p className={`text-white max-w-[332px] font-[300] open-sans text-[14px] xl:text-[16px] mt-[15px] ${active === 2 ? 'block' : 'hidden'}`}>{t('supportfaq.16')}</p>
                    </div>
                </div>
                <img src={arrow} alt="" className='ml-[-40px] z-50 cursor-pointer' onClick={() => next()}/>
            </div>
            <div className='flex flex-col xl:hidden mt-[30px]'>
                <p className='text-[14px] text-[#98CBFF] font-[600] oswald'>{t('supportfaq.11')}</p>
                <p className='text-[14px] text-white font-[300] open-sans mt-[15px]'>{t('supportfaq.12')}</p>
                <p className='text-[14px] text-[#98CBFF] font-[600] oswald mt-[30px]'>{t('supportfaq.13')}</p>
                <p className='text-[14px] text-white font-[300] open-sans mt-[15px]'>{t('supportfaq.14')}</p>
                <p className='text-[14px] text-[#98CBFF] font-[600] oswald mt-[30px]'>{t('supportfaq.15')}</p>
                <p className='text-[14px] text-white font-[300] open-sans mt-[15px]'>{t('supportfaq.16')}</p>
            </div>
        </div>
    </div>

  );
}

export default CustomerSupport;