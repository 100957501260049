import React from 'react';

import { useTranslation, Trans } from "react-i18next";

import imgman from '../../img/Home/imgman.png'

import Fade from 'react-reveal/Fade'

function Discover() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#000] mt-[80px] xl:mt-[160px] relative overflow-hidden'>
       <div className='flex flex-col xl:flex-row justify-between max-w-[1280px] mx-[20px] xl:mx-auto'>
            <div>
                <p className='oswald text-[20px] xl:text-[45px] font-[700] text-[#98CBFF] uppercase'><Trans>{t('main.35')}</Trans></p>
                <p className='xl:max-w-[525px] mt-[15px] xl:mt-[30px] text-white open-sans text-[14px] xl:text-[17px] font-[300]'>{t('main.36')}</p>
                <p className='xl:max-w-[525px] mt-[15px] text-white open-sans text-[14px] xl:text-[17px] font-[300]'>{t('main.37')}</p>
            </div>
            <div>
                <Fade right>
                    <img src={imgman} alt="" />
                </Fade>
            </div>
       </div>
    </div>

  );
}

export default Discover;