import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import ast1 from '../../img/Home/ast1.png'
import ast2 from '../../img/Home/ast2.png'

import Fade from 'react-reveal/Fade';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import img1 from '../../img/Home/img3.png'
import img2 from '../../img/Home/img2.png'
import img3 from '../../img/Home/img1.png'

import arrow from '../../img/Home/arrow.png'

function Diversity() {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

  return (
    <div className='bg-[#000] mt-[80px] xl:mt-[160px] pb-[50px] xl:pb-[30px] overflow-hidden relative'>
        <Fade left>
          <img src={ast1} alt="" className='absolute left-0 hidden xl:block'/>
        </Fade>
        <Fade right>
          <img src={ast2} alt="" className='absolute right-0 hidden xl:block'/>
        </Fade>
        <p className='text-center mx-auto oswald text-[20px] xl:text-[50px] text-[#98CBFF] uppercase font-[700]'>{t('main.22')}</p>
        <p className='text-center mx-auto oswald text-[20px] xl:text-[45px] text-[#FFF] uppercase font-[700]'>{t('main.23')}</p>
        <p className='max-w-[706px] text-center mt-[15px] xl:mt-[25px] mx-[20px] xl:mx-auto text-white open-sans text-[14px] xl:text-[17px] font-[300]'>{t('main.24')}</p>
        <Swiper
            modules={[Autoplay, Pagination]}
            slidesPerView={1}
            pagination={true}
            loop={true}
            onInit={(e) => {
                setSwiper(e);
            }}
            className='xl:w-[802px] mt-[20px] xl:mt-[75px] !overflow-y-visible'
        >
            <SwiperSlide>
                <div className='h-[200px] xl:h-auto'>
                  <img src={img1} alt="" className='object-cover h-full w-full'/>
                  <div className='absolute inset-[20px] xl:inset-[50px]'>
                    <p className='oswald text-[16px] xl:text-[25px] font-[600] text-[#98CBFF]'>Bitcoin (BTC)</p>
                    <p className='max-w-[370px] text-white text-[14px] xl:text-[16px] open-sans mt-[15px] font-[300]'>{t('main.26')}</p>
                  </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='h-[200px] xl:h-auto'>
                  <img src={img2} alt="" className='object-cover h-full w-full'/>
                  <div className='absolute inset-[20px] xl:inset-[50px]'>
                    <p className='oswald text-[16px] xl:text-[25px] font-[600] text-[#98CBFF]'>Ethereum (ETH)</p>
                    <p className='max-w-[370px] text-white text-[14px] xl:text-[16px] open-sans mt-[15px] font-[300]'>{t('main.27')}</p>
                  </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='h-[200px] xl:h-auto'>
                  <img src={img3} alt="" className='object-cover h-full w-full'/>
                  <div className='absolute inset-[20px] xl:inset-[50px]'>
                    <p className='oswald text-[16px] xl:text-[25px] font-[600] text-[#98CBFF]'>Altcoins</p>
                    <p className='max-w-[370px] text-white text-[14px] xl:text-[16px] open-sans mt-[15px] font-[300]'>{t('main.28')}</p>
                  </div>
                </div>
            </SwiperSlide>
        </Swiper>
        <div className='xl:mx-auto xl:w-[900px] mt-[-10px] xl:mt-[-180px] mx-[20px]'>
          <div className='flex justify-between'>
            <img src={arrow} alt="" className='rotate-180 cursor-pointer' onClick={() => swiper.slidePrev()}/>
            <img src={arrow} alt="" className='cursor-pointer' onClick={() => swiper.slideNext()}/>
          </div>
        </div>
        <div className='h-[130px]'></div>
    </div>

  );
}

export default Diversity;