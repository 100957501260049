import React, { useEffect } from "react";

import Main from '../components/AboutUs/Main';
import OurVision from '../components/AboutUs/OurVision';
import OurMission from '../components/Accounts/OurMission';

function AboutUs() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='bg-[#000]'>
        <Main/>
        <OurVision/>
        <OurMission/>
        <div className='h-[100px] xl:h-[200px]'></div>
    </div>
  );
}

export default AboutUs;