import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

const data = [
    {
        title:  'supportfaq.19',
        answer: 'supportfaq.20',
    },
    {
        title:  'supportfaq.21',
        answer: 'supportfaq.22',
    },
    {
        title:  'supportfaq.23',
        answer: 'supportfaq.24',
    },
    {
        title:  'supportfaq.25',
        answer: 'supportfaq.26',
    },
    {
        title:  'supportfaq.27',
        answer: 'supportfaq.28',
    },
    {
        title:  'supportfaq.29',
        answer: 'supportfaq.30',
    },
    {
        title:  'supportfaq.31',
        answer: 'supportfaq.32',
    },
    {
        title:  'supportfaq.33',
        answer: 'supportfaq.34',
    },
    {
        title:  'supportfaq.35',
        answer: 'supportfaq.36',
    },
    {
        title:  'supportfaq.37',
        answer: 'supportfaq.38',
    },
]

function FAQ() {

  const { t } = useTranslation();

  const [openIndex, setOpenIndex] = useState(1);

  const toggleText = (index) => {
      setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className='relative overflow-hidden'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col items-center'>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF] text-center'>{t('supportfaq.18')}</p>
            <div className="flex justify-between flex-col mt-[20px] xl:mt-[100px] xl:w-[1280px]">
                {data.map((faq, index) => (
                    <div key={index} onClick={() => toggleText(index)} className='cursor-pointer'>
                        <div className={`flex justify-between mt-[5px] transition-all duration-300`}>
                            <div className='xl:mx-[25px]'>
                                <p className='mt-[15px] oswald text-[14px] xl:text-[20px] text-[#98CBFF] font-[600] tracking-[3.4px]'>{index + 1}. {t(faq.title)}</p>
                                {openIndex === index && (
                                <p className='text-[#E7E7E7] xl:ml-[42px] open-sans text-[14px] xl:text-[17px] font-light mt-[10px] max-w-[757px] tracking-[2.89px]'>{t(faq.answer)}</p>
                                )}
                            </div>
                        </div>
                        <div className='h-[1px] bg-white mt-[20px]'></div>
                    </div>
                ))}
            </div>
        </div>
    </div>

  );
}

export default FAQ;