import React, { useState } from 'react';

import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

import button from '../../img/TradingAssets/button.png'

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';

import sw1 from '../../img/TradingAssets/sw1.png'
import sw2 from '../../img/TradingAssets/sw2.png'
import sw3 from '../../img/TradingAssets/sw3.png'

import block from '../../img/TradingAssets/block.png'

function TradingFees() {

  const { t } = useTranslation();

  const [swiper, setSwiper] = useState({});

  return (
    <div className='bg-[#000] mt-[80px] xl:mt-[160px] relative overflow-hidden xl:flex flex-col'>
        <p className='text-center mx-auto oswald text-[20px] xl:text-[50px] text-[#98CBFF] uppercase font-[700]'>{t('tradingassets.14')}</p>
        <p className='max-w-[730px] text-center mt-[15px] xl:mt-[30px] mx-[20px] xl:mx-auto text-white open-sans text-[14px] xl:text-[17px] font-[300]'>{t('tradingassets.15')}</p>
        <Swiper
            modules={[Autoplay]}
            slidesPerView={1}  
            onInit={(e) => {
                setSwiper(e);
            }}
            className='xl:w-[1280px] mt-[20px] xl:mt-[75px] !overflow-y-visible'
            breakpoints={{
                300: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1280:{
                    slidesPerView: 3,
                }
            }}
        >
            <SwiperSlide>
                <div className='flex flex-col relative'>
                    <img src={block} alt="" className='mx-auto'/>
                    <div className='absolute inset-0 flex flex-col items-center'>
                        <img src={sw1} alt="" className='mt-[11px]'/>
                        <p className='mt-[40px] oswald text-[14px] xl:text-[18px] text-[#98CBFF] font-[600]'>{t('tradingassets.16')}</p>
                        <p className='max-w-[265px] open-sans text-[14px] xl:text-[16px] text-white font-[300] mt-[15px] text-center'>{t('tradingassets.17')}</p>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='flex flex-col relative'>
                    <img src={block} alt="" className='mx-auto'/>
                    <div className='absolute inset-0 flex flex-col items-center'>
                        <img src={sw2} alt="" className='mt-[11px]'/>
                        <p className='mt-[40px] oswald text-[14px] xl:text-[18px] text-[#98CBFF] font-[600]'>{t('tradingassets.18')}</p>
                        <p className='max-w-[265px] open-sans text-[14px] xl:text-[16px] text-white font-[300] mt-[15px] text-center'>{t('tradingassets.19')}</p>
                    </div>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className='flex flex-col relative'>
                    <img src={block} alt="" className='mx-auto'/>
                    <div className='absolute inset-0 flex flex-col items-center'>
                        <img src={sw3} alt="" className='mt-[11px]'/>
                        <p className='mt-[40px] oswald text-[14px] xl:text-[18px] text-[#98CBFF] font-[600]'>{t('tradingassets.20')}</p>
                        <p className='max-w-[265px] open-sans text-[14px] xl:text-[16px] text-white font-[300] mt-[15px] text-center'>{t('tradingassets.21')}</p>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
        <div className='xl:flex items-center gap-[25px] mx-auto hidden'>
            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="12" viewBox="0 0 66 12" fill="none" className='mt-[40px]'>
                <circle cx="6" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
                <circle cx="33" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
                <circle cx="60" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
            </svg>
            <div className='button-main mt-[35px] cursor-pointer'>
                <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="66" height="12" viewBox="0 0 66 12" fill="none" className='mt-[40px]'>
                <circle cx="6" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
                <circle cx="33" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
                <circle cx="60" cy="6" r="6" fill="#98CBFF" fill-opacity="0.5"/>
            </svg>
        </div>
        <div className='xl:hidden flex gap-[25px] mx-[20px] mt-[20px]'>
            <img src={button} alt="" className='rotate-180 cursor-pointer' onClick={() => swiper.slidePrev()}/>
            <img src={button} alt="" className='cursor-pointer' onClick={() => swiper.slideNext()}/>
        </div>
    </div>

  );
}

export default TradingFees;