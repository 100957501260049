import React from 'react';

import { useTranslation } from "react-i18next";

import logo from '../img/logo.png'

function Footer() {

  const { t } = useTranslation();

  return (
    <div className='bg-[#000] border-t'>
      <div className='z-10 relative max-w-[1280px] mx-auto'>
        <div className='flex flex-col xl:flex-row container mx-auto pt-[160px]'>
          <img src={logo} alt="" className='w-[50px] h-[50px] ml-[30px] xl:ml-0'/>
          <div className='ml-[30px] xl:ml-[150px] gap-[15px] flex flex-col mt-[30px] xl:mt-0'>
            <p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer1')} +441212696406</p>
            <div>
              <p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer2')} Wächtergasse 1/502, 1010 Wien, Austria</p>
            </div>
            <p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer3')}support@fips-finance.com</p>
          </div>
          <div className='ml-[30px] xl:ml-[350px] gap-[15px] flex flex-col mt-[15px] xl:mt-0'>
            <a href="/terms.pdf"><p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer4')}</p></a>
            <a href="/policy.pdf"><p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer5')}</p></a>
            <a href="/aml.pdf"><p className='text-[#FFFFFFB2] montserrat text-[14px] xl:text-[16px] font-normal'>{t('footer6')}</p></a>
          </div>
        </div>
        <div className='flex flex-col container mx-auto pl-[30px] xl:pl-[205px] pt-[40px]'>
          <p className='text-[#FFFFFFB2] montserrat text-[12px] font-normal'>{t('footer7')}</p>
          <p className='text-[#FFFFFFB2] montserrat text-[12px] font-light mt-[6px] pr-[30px] xl:pr-0'>{t('footer8')}</p>
          <p className='flex justify-end pb-[150px] mt-[14px] montserrat text-[16px] text-[#FFFFFFB2] pr-[30px] xl:pr-0'>FIPS Finance & Development © 2024. All rights reserved.</p>      
        </div>
      </div>
    </div>
   
  );
}

export default Footer;