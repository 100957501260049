import React from 'react';

import { Link } from 'react-router-dom';

import { useTranslation, Trans } from "react-i18next";

import earth from '../../img/TradingAssets/earth.png'
import man from '../../img/TradingAssets/man.png'
import commet from '../../img/TradingAssets/commet.png'
import component from '../../img/Home/component.png'

import Fade from 'react-reveal/Fade'
import Zoom from 'react-reveal/Zoom'

function Main() {

  const { t } = useTranslation();

  return (
    <div className='relative overflow-hidden'>
        <div className='max-w-[1280px] mx-[20px] xl:mx-auto xl:mt-[180px] mt-[100px] flex flex-col'>
            <p className='oswald text-[20px] xl:text-[45px] uppercase font-[700] text-white text-center'>{t('tradingassets.1')}</p>
            <p className='oswald text-[20px] xl:text-[55px] uppercase font-[700] text-[#98CBFF] text-center'>{t('tradingassets.2')}</p>
            <div className='button-main mt-[35px] cursor-pointer mx-auto'>
                <p className='text-[#F1EDEE] text-[17px] furore'>{t('main.2')}</p>
            </div>
            <Zoom bottom left><img src={man} className='absolute z-50 ml-[100px] top-[450px]' alt="" /></Zoom>
            <Zoom top right duration={3000}><img src={commet} alt="" className='absolute right-0'/></Zoom>
            <Fade bottom duration={2000}><img src={earth} alt="" className='mx-auto mt-[200px]'/></Fade>
        </div>
        <div className='shadow-main w-full h-[710px] mt-[-700px] xl:mt-[-400px] z-[500] relative'>
            <img src={component} className='hidden xl:block absolute left-1/2 -translate-x-1/2 top-[350px]' alt="" />
            <div className='h-[390px]'></div>
            <div className='block-main h-[233px] max-w-[1280px] flex items-center justify-center flex-col mx-[20px] xl:mx-auto'>
            <p className='max-w-[660px] open-sans font-[300] xl:text-[17px] text-[#FFF]  text-center mx-[10px] xl:mx-0 texttrade'><Trans>{t('tradingassets.3')}</Trans></p>
            </div>
        </div>
    </div>

  );
}

export default Main;